interface Props {
  width: number;
  className?: string;
}

export const SyncWithLogo = (props: Props) => {
  const { width, className } = props;
  return (
    <svg
      className={className}
      width={width}
      viewBox="0 0 101 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7577 12.4662C21.0882 12.6567 21.0882 13.1323 20.7577 13.3228L11.6605 18.5669C11.3297 18.7575 10.916 18.5195 10.916 18.1386L10.916 7.65048C10.916 7.26952 11.3297 7.03152 11.6605 7.22219L20.7577 12.4662Z"
        fill="#FF005C"
      />
      <path
        d="M9.84174 6.05742C10.1722 6.2479 10.1722 6.72352 9.84174 6.914L0.744489 12.158C0.413717 12.3487 -1.67009e-08 12.1107 0 11.7297L4.59787e-07 1.24167C4.76488e-07 0.86071 0.413716 0.622706 0.744489 0.813378L9.84174 6.05742Z"
        fill="#FF005C"
      />
      <path
        d="M9.84174 19.086C10.1722 19.2765 10.1722 19.7521 9.84174 19.9426L0.744489 25.1866C0.413717 25.3773 -1.67009e-08 25.1393 0 24.7583L4.59787e-07 14.2702C4.76488e-07 13.8893 0.413716 13.6513 0.744489 13.8419L9.84174 19.086Z"
        fill="#FF005C"
      />
      <path
        d="M92.5684 18.704V6.10522H94.9933V10.9022H95.0636C95.4853 9.70738 96.4869 9.14509 97.7169 9.14509C99.6322 9.14509 100.792 10.5508 100.792 12.5715V18.704H98.367V13.4501C98.367 12.2552 97.7696 11.4294 96.7329 11.4294C95.6083 11.4294 94.9933 12.3782 94.9933 13.661V18.704H92.5684Z"
        fill="white"
      />
      <path
        d="M89.3288 18.7038C87.6595 18.7038 86.5877 18.2118 86.5877 16.0681V11.5698H85.1468V9.46125H86.5877V7.05396H89.0125V9.46125H91.0859V11.5698H89.0125V15.8397C89.0125 16.4547 89.2058 16.7358 89.8735 16.7358H91.0859V18.7038H89.3288Z"
        fill="white"
      />
      <path
        d="M81.6868 18.7039V9.46127H84.1117V18.7039H81.6868ZM81.4584 7.15941C81.4584 6.33355 82.1261 5.66583 82.952 5.66583C83.7778 5.66583 84.428 6.33355 84.428 7.15941C84.428 7.98527 83.7778 8.5827 82.952 8.5827C82.1261 8.5827 81.4584 7.98527 81.4584 7.15941Z"
        fill="white"
      />
      <path
        d="M67.9866 18.704L64.8413 6.10522H67.3892L69.0057 12.6242C69.1815 13.3095 69.3045 14.0827 69.445 15.0315H69.5153C69.6559 14.0827 69.7789 13.3447 69.9546 12.6418L71.4833 6.10522H74.4353L75.9816 12.7121C76.1573 13.4325 76.2803 14.153 76.3682 15.0315H76.4385C76.5791 14.153 76.7021 13.4325 76.8953 12.7121L78.5646 6.10522H81.0246L77.8266 18.704H75.0503L73.627 12.5715C73.3459 11.3591 73.1175 10.0588 72.9769 9.00452H72.9066C72.766 10.0588 72.5376 11.3591 72.2389 12.5891L70.8156 18.704H67.9866Z"
        fill="white"
      />
      <path
        d="M61.497 19.0201C58.5977 19.0201 57.1041 17.0697 57.1041 14.188C57.1041 11.236 58.7207 9.14496 61.6024 9.14496C63.7637 9.14496 65.3978 10.2871 65.5911 12.7647H63.2541C63.0433 11.9388 62.481 11.359 61.5145 11.359C60.1088 11.359 59.529 12.5538 59.529 14.1528C59.529 15.6464 60.021 16.8061 61.5145 16.8061C62.481 16.8061 63.0784 16.2965 63.2541 15.418H65.5911C65.3451 17.7726 63.8515 19.0201 61.497 19.0201Z"
        fill="white"
      />
      <path
        d="M47.7028 18.7038V9.46125H49.9871V10.9021H50.0574C50.5142 9.70725 51.6212 9.14496 52.8512 9.14496C54.7665 9.14496 55.9087 10.5507 55.9087 12.5714V18.7038H53.5014V13.45C53.5014 12.2551 52.904 11.4292 51.8672 11.4292C50.7427 11.4292 50.1277 12.3781 50.1277 13.6433V18.7038H47.7028Z"
        fill="white"
      />
      <path
        d="M38.663 22.4467V20.4435H40.6134C41.0703 20.4435 41.4217 20.3732 41.5447 20.1097L41.5799 20.0745C41.6326 19.9691 41.6853 19.8285 41.6853 19.688C41.6853 19.2662 41.3866 18.6512 41.0176 17.6672L37.9601 9.46136H40.4728L41.8083 13.0811C42.2827 14.3462 42.5463 15.4532 42.7044 16.2967H42.7747C42.8977 15.4532 43.1613 14.3462 43.5127 13.0811L44.5494 9.46136H46.8337L43.5303 20.2678C43.0383 21.902 42.2651 22.4467 40.1741 22.4467H38.663Z"
        fill="white"
      />
      <path
        d="M33.0008 19.0202C30.2421 19.0202 27.9051 17.7726 27.9578 14.5395H30.5408C30.5408 16.1736 31.6303 16.771 33.1238 16.771C34.3011 16.771 35.2324 16.4196 35.2324 15.3302C35.2324 14.5395 34.7229 14.0299 33.528 13.6785L31.4194 13.0635C30.0313 12.6593 28.2566 11.7808 28.2566 9.47889C28.2566 6.93103 30.2246 5.78888 32.7021 5.78888C35.1973 5.78888 37.5167 6.93102 37.5167 9.84789H34.9337C34.8459 8.33674 33.7564 7.98531 32.7021 7.98531C31.7533 7.98531 30.8571 8.26646 30.8571 9.25046C30.8571 9.84789 31.191 10.3926 32.4737 10.7792L34.6174 11.4293C36.0934 11.8862 37.833 12.712 37.833 15.1545C37.833 17.4915 36.1989 19.0202 33.0008 19.0202Z"
        fill="white"
      />
    </svg>
  );
};
